import React, { useState, useEffect } from "react";
import { api } from "../../services/api";
import swal from "sweetalert";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import dashboardWeb from "../../assets/dashboard-web.png";
import dashboardMovil from "../../assets/dashboard-movil.png";
import { BrowserView, MobileView } from "react-device-detect";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Grid } from "@mui/material";
import imgDashboard from "../../assets/img-dashboard.png";
import imgWebpay from "../../assets/webpaylogo.png";
import googlePlayLogo from "../../assets/google_play_logo.png";
import appStoreLogo from "../../assets/app_store_logo.png";


export const Dashboard = () => {

    return (
        <>
            <Grid container mt={5}>
                <Grid md={2} textAlign={'center'}>
                </Grid>
                <Grid md={6} textAlign={'center'}>
                    <p style={{ fontSize: 40, fontWeight: 'bold', color: '#e30613' }}>¡Compra con descuento!</p>
                    <p style={{ fontSize: 15, marginTop: 4 }}>A partir de ahora tus compras de entradas son a través <br /> de la App Cinesol</p>
                </Grid>
            </Grid>
            <Grid container mt={5} mb={4}>
                <Grid md={1} textAlign={'center'}>
                </Grid>
                <Grid md={5} textAlign={'center'}>
                    <img src={imgDashboard} width={'80%'} alt="img" />
                </Grid>
                <Grid container md={4} alignItems={'baseline'}>
                    <Grid container md={12}>
                        <Grid mx={'auto'} textAlign={'center'} mb={3}>
                            <p style={{ fontSize: 15 }}>Utiliza el código CINESOL10 y obten 10% de descuento</p>
                            <p style={{ fontSize: 40, fontWeight: 'bold', color: '#e30613' }}>¡Descarga nuestra app <br /> gratis!</p>
                        </Grid>
                        <Grid container md={12} mb={3}>
                            <Grid md={6} textAlign={'center'}>
                                <a href="https://play.google.com/store/apps/details?id=com.jeff26.cinesol">
                                    <img src={googlePlayLogo} width={'70%'} alt="img" />
                                </a>
                            </Grid>
                            <Grid md={6} textAlign={'center'}>
                                <a href="https://apps.apple.com/app/app-cinesol/id6475873175">
                                    <img src={appStoreLogo} width={'70%'} alt="img" />
                                </a>
                            </Grid>
                        </Grid>
                        <Grid mx={'auto'} textAlign={'center'}>
                            <p style={{ fontSize: 12 }}>Crea tu cuenta y paga tus entradas con</p>
                            <img src={"https://jumpseller.co/images/landing/transbank/webpay.svg"} width={'90%'} alt="img" />
                        </Grid>
                    </Grid>
                    <Grid md={12} textAlign={'center'} mt={'auto'}>
                        <p style={{ fontSize: 15 }}>(Descuento sólo válido en APP CINESOL, Aplican TyC restricciones, ni estrenos ni eventos).</p>
                    </Grid>
                </Grid>
            </Grid>
        </>

    );
};
